<template>
     <div>
          <v-row>
               <v-col cols="12">
                    <h2>Change settings</h2>
               </v-col>
               
               <v-col cols="12" v-if="email">
                    <v-text-field 
                         type="text"
                         v-model="form.email"
                         :label="$t('attributes.email.label')"
                         name="email"
                         required
                         :hint="$t('attributes.email.hint')"
                         :error-messages="errors.email"
                         @focus="errors.email = []"
                    >
                    </v-text-field>
               </v-col>
               <v-col cols="12" v-if="phone">
                    <p>Mantain format with prefix es: '+393407498983', no space</p>
                    <v-text-field 
                         type="text"
                         v-model="form.phone"
                         :label="$t('attributes.phone.label')"
                         name="phone"
                         required
                         :hint="$t('attributes.phone.hint')"
                         :error-messages="errors.phone"
                         @focus="errors.phone = []"
                    >
                    </v-text-field>
               </v-col>

               <v-col cols="12">
                    <v-btn :loading="loading" color="primary" block @click="saveProfile">SAVE</v-btn>
               </v-col>
          </v-row>
     </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  components: {  },
     name: "FormUserSettings",
     computed: {
     ...mapGetters({
          isAdmin: 'auth/isAdmin',
      }),
     },
     props:{
          uid:{
               type:String,
               required:true
          },
          email:{
               type: String,
               deafault:null
          },
          phone:{
               type: String,
               deafault:null
          }
     },
     beforeMount(){
         this.form.email = this.email
         this.form.phone = this.phone
     },
     data: () => ({
          errors: {},
          form:{
               email: null,
               phone: null,
          },
          loading: false,
          maxdate: null,
          mindate: null,
     }),
     methods:{
          async saveProfile(){
               this.loading = true;

               var params = {
                    uid: this.uid,
               }

               if(this.email){
                    params.email = this.form.email
               }else if(this.phone){
                    params.phone = this.form.phone
               }
               

               //var storeName = (this.isAdmin) ? 'adminUsers/updateSettings' : 'managerUsers/updateSettings'

               this.$store.dispatch('managerUsers/updateSettings', params).then((response) => {
                    console.log('updateSettings success!',response)
                    this.loading = false;
                    location.reload();
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          }
     }
}
</script>