<template>
     <v-row>
          <v-col cols="12" class="text-end">
               <v-btn @click="getUserCoupons" color="primary" icon>
                    <v-icon>mdi-reload</v-icon>
               </v-btn>
          </v-col>
          <v-col cols="11" class="mx-auto">
               <v-card>
               <template  v-for="coupon in coupons" >
                    <v-card-text :key="coupon.coupon_uid+'coupon'" class="coupon">
                         <v-row class="text-center" center align="center" justify="center" :class="(!coupon.usable) ? 'disabled' : null">
                              <v-col cols="4" md="2">
                                    <template  v-for="level in coupon.valid_levels" >
                                   <div :key="level">
                                        <v-icon
                                        :key="level"
                                        :color="level"
                                        class="mx-1 mb-1"
                                        >
                                        mdi-ticket
                                        </v-icon><br>
                                   </div>
                                   </template>
                              </v-col>
                              <v-col cols="8" md="6" class="text-left">
                                    <h2>{{coupon.title}}</h2>
                                   {{coupon.description}}
                                   <template v-if="coupon.expire_at">
                                        <br>
                                        <small>
                                        {{$t('commons.usable_by_date')}} 
                                        {{ [ coupon.expire_at, "YYYY-MM-DDTHH:mm:ss.ssssssZ" ] | moment("D MMMM YYYY") }}
                                        </small>
                                   </template>
                              </v-col>
                              <v-col cols="12" md="4">
                                   <div :key="coupon.status">
                                        <dialog-simple width="900px" block :disabled="!coupon.usable" :button="$t('commons.use')+' '+$t('commons.coupon')" :title="$t('commons.use')+' '+$t('commons.coupon')">
                                             <slot>
                                                  <use-user-coupon :coupon="coupon" :uid="uid" :key="coupon.coupon_uid"></use-user-coupon>
                                             </slot>
                                        </dialog-simple>
                                        <template v-if="coupon.points > 0">
                                             {{ coupon.points }} {{$t('commons.points')}} 
                                        </template>
                                   </div>
                              </v-col>
                         </v-row>
                    </v-card-text>
                    <v-divider :key="coupon.coupon_uid+'-divider'"></v-divider>
                   </template>
               
               </v-card>
          </v-col>
          <v-col>
               <div class="text-center py-3">
                    <v-pagination
                         v-model="meta.current_page"
                         :length="meta.last_page"
                         :total-visible="5"
                         :disabled="loading || (meta.last_page==1)" 
                         @input="page(meta.current_page)"
                    ></v-pagination>
               </div>
          </v-col>
     </v-row>
</template>


<script>
import {mapGetters} from "vuex";
import DialogSimple from '@/components/Commons/DialogSimple.vue';
import UseUserCoupon from '@/components/User/Coupons/UseUserCoupon.vue';

export default {
     name: "CouponsList",
     components:{
          'dialog-simple': DialogSimple,
          'use-user-coupon': UseUserCoupon,
     },
     computed: {
          ...mapGetters({
                coupons: 'managerUsersCoupon/coupons',
                meta: 'managerUsersCoupon/meta',
                isAdmin: 'auth/isAdmin',
          }),
     },
     props:{
          uid:{
               type: String,
               required: true,
          }
     },
     beforeMount(){
          
          this.getUserCoupons()
          
     },
     data: () => ({
          loading: true,
     }),
     methods:{
          async getUserCoupons(){
               this.loading = true;
               this.$store.dispatch('managerUsersCoupon/getUserCoupons',this.uid).then((response) => {
                    console.log('getUserCoupons success!',response)
                    this.loading = false;
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          },
          page(page){
               this.loading = true
               this.meta.current_page = page
               this.$store.commit('managerUsersCoupon/meta',this.meta)
               this.getUserCoupons()
          }
     },
}
</script>

<style scoped>
     .coupon .disabled{
          opacity: 0.5;
          cursor: not-allowed;
     }
     .coupon .btn .disabled{
          opacity: 0.5;
          cursor: not-allowed;
     }
</style>