<template>
     <v-card :loading="loading">
          <v-card-title primary-title>
               <v-row  align="center" justify="center">
                    <v-col cols="12" md="2" class="text-center">
                         <v-avatar>
                              <v-img :src="'/img/flags/'+user.country.toLowerCase()+'.png'"></v-img>
                         </v-avatar>
                    </v-col>
                    <v-col class="text-md-left text-center">
                         <h3>{{user.email}}</h3>
                         {{user.profile.fullname}}
                    </v-col>
                    <v-col cols="12" md="3" class="text-md-right text-center">
                          {{user.uid}}
                    </v-col>
               </v-row>
          </v-card-title>
          <v-divider></v-divider>
          <v-row class="pa-5" v-if="alert.visible">
               <v-col cols="12">
                    <v-alert
                         border="left"
                         :type="alert.type"
                         :icon="alert.icon"
                         prominent
                    >
                    <v-row align="center">
                    <v-col class="grow">
                         <h3>{{ $t(alert.title) }}</h3>
                         {{ $t(alert.text) }}
                    </v-col>
                    <v-col >
                         <v-btn 
                              block 
                              color="dark" 
                              target="_blank"
                              :to="alert.btn_to" 
                         >{{ $t(alert.btn_text) }}
                         </v-btn>
                    </v-col>
                    </v-row>
                    </v-alert>
               </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row class="pa-5" center>
               <v-col cols="12" md="3" class="text-center">
                    <v-progress-circular 
                    :rotate="-90"
                    :color="user.points.level" 
                    :value="user.points.percent"
                    :size="150"      
                    :width="15"
                    block
                    >
                    <span class="h1 display-3">{{user.points.total_points}}</span>
                    </v-progress-circular>
               <br>
                    <h3 :class="'h1 display-1 my-3 '+ user.points.level+'--text'">
                         {{$t('commons.levels.'+user.points.level+'.title')}}
                    </h3>
               </v-col>
               <v-divider vertical></v-divider>
               <v-col cols="12" md="6">
                    <h4>{{ $t('commons.profile') }}</h4>
                    <simple-table v-if="!alert.visible" :table="{
                         'uid':user.uid,
                         'email':user.email,
                         'phone':user.phone,
                         'country':user.country+'-'+user.language,
                         'city':user.profile.city+', '+user.profile.zip_code,
                         'fullname':user.profile.fullname,
                         'date_of_birth':user.profile.date_of_birth,
                         'age':user.profile.age,
                    }" ></simple-table>
                    <simple-table v-else :table="{
                         'uid':user.uid,
                         'email':$t('user.hidden-data'),
                         'phone':$t('user.hidden-data'),
                         'country': $t('user.hidden-data'),
                         'city':$t('user.hidden-data'),
                         'fullname':$t('user.hidden-data'),
                         'date_of_birth':$t('user.hidden-data'),
                         'age':$t('user.hidden-data'),
                    }" ></simple-table>
               </v-col>
               <v-divider vertical></v-divider>
               <v-col cols="12" md="3">
                    <v-row class="text-center">
                         <v-col cols="12">
                             <h5>{{ $t('attributes.email_is_verified.label') }}</h5>
                             <div v-if="user.email_verified_at">
                                  <v-icon color="success accent-4">mdi-check</v-icon><br>
                                 {{ [ user.email_verified_at ] | moment("dddd, D MMMM YYYY") }}
                             </div>
                             <div v-else>
                                  <v-icon color="red accent-4 ">mdi-cancel</v-icon>
                                  <br>
                                  <!-- <v-btn 
                                    target="_blank" 
                                    :to="{name:'resend-email-verification',query:{'email':user.email}}" 
                                    class="my-1"
                                    color="primary">
                                    {{$t('commons.resend-email-verification.btn')}}
                                  </v-btn> -->
                                  <dialog-simple btnclass="my-1" :button="$t('commons.resend-email-verification.btn')" :title="$t('attributes.email_is_verified.label')">
                                       <FormResendEmailVerification :email="user.email"/>
                                  </dialog-simple>
                             </div>
                         </v-col>
                         <v-col><v-divider></v-divider></v-col>
                         <v-col cols="12">
                             <h5>{{ $t('attributes.phone_is_verified.label') }}</h5>
                             <div v-if="user.phone_verified_at">
                                  <v-icon color="success accent-4">mdi-check</v-icon><br>
                                 {{ [ user.phone_verified_at ] | moment("dddd, D MMMM YYYY") }}
                             </div>
                             <div v-else>
                                  <v-icon color="red accent-4">mdi-cancel</v-icon>
                                  <br>
                                  <!-- <v-btn 
                                    target="_blank" 
                                    :to="{name:'resend-sms-verification',query:{'phone':user.phone}}" 
                                    class="my-1"
                                    color="primary">
                                    {{$t('commons.resend-sms-verification.btn')}}
                                  </v-btn>
                                  <br>
                                  <v-btn 
                                    target="_blank" 
                                    :to="{name:'resend-sms-verification',query:{'sent':'true'}}" 
                                    class="my-1"
                                    color="primary">
                                    {{$t('commons.resend-sms-verification.verify')}}
                                  </v-btn> -->
                                  <dialog-simple btnclass="my-1" :button="$t('commons.resend-sms-verification.btn')" :title="$t('attributes.phone_is_verified.label')">
                                       <FormResendSmsVerification :phone="user.phone" @refresh="refreshUser"/>
                                  </dialog-simple>
                                  <br>
                                  <dialog-simple btnclass="my-1" :button="$t('commons.resend-sms-verification.verify')" :title="$t('attributes.phone_is_verified.label')">
                                       <FormResendSmsVerification sent :phone="user.phone"  @refresh="refreshUser"/>
                                  </dialog-simple>
                             </div>
                         </v-col>
                    </v-row>
               </v-col>
               <v-col cols="12">
                    <v-divider></v-divider>
               </v-col>
               <v-col cols="12" md="8">
                    <h4>{{ $t('commons.privacy') }}</h4>
                    <simple-table v-if="!alert.visible" :table="{
                         'general':user.privacy.general,
                         'marketing':user.privacy.marketing,
                         //'survey':user.privacy.survey,
                         'third_party':user.privacy.third_party,
                         'user_confirm': (user.privacy.user_confirm) ? true : false,
                         'updated_at':user.privacy.updated_at,
                         'created_at':user.created_at,
                         'expired_at':user.privacy.expired_at,
                    }" ></simple-table>
                    <simple-table v-else :table="{
                         'general':$t('user.hidden-data'),
                         'marketing':$t('user.hidden-data'),
                         //'survey':$t('user.hidden-data'),
                         'third_party':$t('user.hidden-data'),
                         'user_confirm': false,
                         'updated_at': user.privacy.updated_at,
                         'created_at': user.created_at,
                         'expired_at': user.privacy.expired_at
                    }" ></simple-table>
               </v-col>

               <v-divider vertical></v-divider>
               <v-col cols="12" md="4" class="text-center">
                    <h4>{{user.qrcode.title}}</h4>
                    <br>
                    <v-img width="50%" class="mx-auto" :src="user.qrcode.url.image"></v-img>
                    <br>
                    <!-- <v-btn 
                         target="_blank" 
                         :to="{name:'resend-card',query:{'uid':user.uid}}" 
                         color="primary">
                         {{$t('commons.resend-card.btn')}}
                    </v-btn> -->
                    <dialog-simple outlined :button="$t('commons.resend-card.btn') +' '+$t('commons.resend-card.title')" :title="$t('commons.resend-card.title')">
                              <FormResendCard disabled :uid="user.uid" />
                    </dialog-simple><br>
                    <dialog-simple outlined btncolor="dark" :button="$t('commons.request-delete.title')" :title="$t('commons.request-delete.title')">
                              <p>
                                    {{ $t('commons.request-delete.text') }}
                              </p>
                              <FormRequestDelete disabled :email="user.email" />
                    </dialog-simple>
                    
               </v-col>
               <v-col cols="12" v-if="isAdmin" v-show="user.roles.includes('manager') || user.roles.includes('admin')">
                    <v-divider></v-divider>
               </v-col>
               <v-col cols="12" v-if="isAdmin" v-show="user.roles.includes('manager') || user.roles.includes('admin')">
                    <dialog-simple outlined :button="$t('commons.resend-password.title')" :title="$t('commons.resend-password.title')">
                         <p> {{ $t('commons.resend-password.text') }} </p>
                         <FromResendNewPassword disabled :uid="user.uid" />
                    </dialog-simple>
               </v-col>
          </v-row>
     </v-card>
</template>

<script>
import FormResendEmailVerification from "@/components/Auth/FormResendEmailVerification"
import FormResendSmsVerification from "@/components/Auth/FormResendSmsVerification"
import FormRequestDelete from "@/components/Auth/FormRequestDelete"
import FormResendCard from "@/components/Auth/FormResendCard"
import FromResendNewPassword from "@/components/Auth/FromResendNewPassword"

export default {
     name:"ViewProfile",
     components:{
          FormResendEmailVerification,
          FormResendSmsVerification,
          FormRequestDelete,
          FormResendCard,
          FromResendNewPassword
     },
     props:{
          loading:{
               type: Boolean,
               default: false
          },
          user:{
               type: Object,
               required: true
          },
          isAdmin:{
               type: Boolean,
               required: false,
               default: false,
          }
     },
     beforeMount(){

          if(this.user.email_verified_at || this.user.phone_verified_at){
               if(!(this.user.privacy.user_confirm)){
                    this.alert = {
                         visible: true,
                         type: "error",
                         icon: "mdi-alert",
                         title: 'user.alerts.verifications-needconfirm.title',
                         text: 'user.alerts.verifications-needconfirm.text',
                         btn_text: 'user.alerts.verifications-needconfirm.button_text',
                         btn_to: {name:'resend-privacy-confirmation',query:{'email':this.user.email}}
                    } 
               }else if( this.$moment(this.user.privacy.expired_at) <= this.$moment()){
                    this.alert = {
                         visible: true,
                         type: "error",
                         icon: "mdi-alert",
                         title: 'user.alerts.verifications-expired.title',
                         text: 'user.alerts.verifications-expired.text',
                         btn_text: 'user.alerts.verifications-expired.button_text',
                         btn_to: {name:'resend-privacy-confirmation',query:{'email':this.user.email}}
                    } 
                    this.expired = true
               }
          }else{

               if(!this.user.email_verified_at){
                    this.alert = {
                              visible: true,
                              type: "warning",
                              icon: "mdi-alert",
                              title: 'user.alerts.verifications-email.title',
                              text: 'user.alerts.verifications-email.text',
                              btn_text: 'user.alerts.verifications-email.button_text',
                              btn_to: {name:'resend-email-verification',query:{'email':this.user.email}}
                    } 
               } else if(!this.user.phone_verified_at ) {
                    this.alert = {
                              visible: true,
                              type: "warning",
                              icon: "mdi-alert",
                              title: 'user.alerts.verifications-phone.title',
                              text: 'user.alerts.verifications-phone.text',
                              btn_text: 'user.alerts.verifications-phone.button_text',
                              btn_to: {name:'resend-phone-verification',query:{'phone':this.user.phone}}
                    } 
               }

          }

     },
     data: () => ({
          expired: false,
          alert: {
               visible: false,
               title: null,
               text: null,
               btn_text: null,
          },
     }),
     methods:{
          refreshUser(){
               console.log("refreshUser")
               location.reload()
          },
          sendNewPassword(uid){
               this.$store.dispatch('managerUsers/sendNewPassword', uid);
          }
     }
}
</script>