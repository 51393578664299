<template>
     <v-row>
          <v-col cols="12">
               <v-divider></v-divider>
          </v-col>
          <v-col v-if="loading">
               <v-progress-linear
                    indeterminate
                    color="red darken-2"
               ></v-progress-linear>
          </v-col>
          <v-col cols="12" v-if="!success">
               <v-row>
                    <v-col cols="12" v-if="Object.keys(errors).length">
                         <v-alert
                              border="top"
                              colored-border
                              type="error"
                              elevation="2"
                         >
                         <h3>{{message}}</h3>
                              <ul>
                                   <li v-for="(err,field) in errors" :key="field">
                                        <b>{{field}}</b>: <span v-for="(e,i) in err" :key="i" >{{e}}</span>
                                   </li>  
                              </ul>
                         </v-alert>
                    </v-col>
                    <v-col cols="12">
                         <h2>{{coupon.title}}</h2>
                         <p>{{coupon.description}}</p>
                    </v-col>
                    <v-col cols="12" class="text-center">
                         {{$t('commons.coupon-tutorial-text')}}<br>
                         <v-chip
                              close
                              class="px-5" 
                              x-large
                              close-icon="mdi-content-duplicate"
                              @click="copy(coupon.coupon_code)"
                              color="orange"
                         >{{coupon.coupon_code}}
                         </v-chip>
                    </v-col>
                    <v-col cols="12" class="text-end" v-if="store">
                         <v-btn :loading="loading" color="primary" @click="useUserCoupon">
                         {{ $t('commons.confirm')}} {{ $t('commons.use')}} {{$t('commons.coupon')}}
                         </v-btn>
                         <p>
                         {{ $t('commons.onclickremovepoint', { points: coupon.points } ) }}
                         </p>
                    </v-col>
                    <v-col cols="12" class="text-end" v-if="!store">
                         NO STORE FOUND TO USE THIS COUPON,<br>
                         ENTER HERE LIKE A MANAGER
                    </v-col>
               </v-row>
          </v-col>
          <v-col cols="12" v-if="success">
               <v-alert
                              border="top"
                              colored-border
                              type="success"
                              elevation="2"
                         >
                        {{ message }}      
               </v-alert>
          </v-col>
     </v-row>
</template>
<script>
import {mapGetters} from "vuex";

export default {
     name:"UseUserCoupon",
     computed: {
          ...mapGetters({
                store: 'managerStores/store'
          }),
     },
     props:{
          coupon: {
               type: Object,
               required: true,
          },
          uid:{
               type: String,
               required: true
          }
     },
     data: () => ({
          loading: false,
          message: null,
          success: false,
          errors: {},
     }),
     methods:{
          async copy(mytext) {
               var input = document.createElement('input');
               input.setAttribute('value', mytext);
               input.value = mytext;        
               document.body.appendChild(input);
               try {
                    input.select();    
                    input.click();     
                    input.focus();
                    var successful = document.execCommand('copy');
                    var msg = successful ? 'successful' : 'unsuccessful';
                    console.log('Testing code was copied ' + successful + ' ' + msg);
                    
               } catch (err) {
                    console.log('Oops, unable to copy');
               }
               document.body.removeChild(input);          
          },
          async useUserCoupon(){
               this.loading = true
               this.errors = {}
               var data = {}
               data.user_uid = this.uid
               data.coupon_uid = this.coupon.coupon_uid
               data.store_uid = this.store.store_uid
               console.log('useUserCoupon',data)
               
               this.$store.dispatch('managerUsersCoupon/useUserCoupon',data).then((response) => {
                    console.log('useUserCoupon success!',response)
                    this.message = response.data.message
                    this.success = true
                    this.loading = false;
                    location.reload();
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.success = false
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 

               this.$store.dispatch('managerUsers/getUser',this.uid)
               this.$store.dispatch('managerUsersCoupon/getUserCoupons',this.uid)
          }
     }
}
</script>
