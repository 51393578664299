<template>
          <div >
               <v-row>
               <v-col cols="12">
                    <h2>Edit</h2>
               </v-col>
               <v-col cols="12">
               <v-simple-table>
                    <tbody>
                    <template v-for="(field) in fillable">
                    <tr :key="field" v-if="field != 'updated_at' || field !='survey'">
                         <th>
                              <p class="text-start p-0">
                              <b>{{$t('attributes.'+field+'.label')}}</b><br>
                              {{$t('privacy.'+field+'.text')}}
                              
                              <template danger v-if="errors[field]">
                                   <span v-for="error in errors[field]" :key="error" class="error--text"><br>{{error}}</span>
                              </template>
                              </p>
                         </th>
                         <td>
                             <v-checkbox
                                   v-model="privacy[field]"
                                   value
                              ></v-checkbox>
                         </td>
                    </tr>
                    </template>
                    </tbody>
               </v-simple-table>
               </v-col>
               <v-col cols="12">
                    <v-btn color="primary" :loading="loading" @click="savePrivacy" block>save</v-btn>
               </v-col>
               </v-row>
          </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
     computed: {
     ...mapGetters({
          isAdmin: 'auth/isAdmin',
      }),
     },
     props:{
          privacy:{
               type: Object,
               required:true
          },
          uid:{
               type: String,
               required:true
          },
     },
     data: () => ({
          errors: {},
          loading: false,
          maxdate: null,
          mindate: null,
          fillable: ['general','marketing','third_party']
     }),
     methods:{
          async savePrivacy(){
               this.loading = true;
               
               var data = {
                    'general': this.privacy.general,
                    'marketing': this.privacy.marketing,
                    'survey': this.privacy.survey,
                    'third_party' : this.privacy.third_party,
               }

               data.uid = this.uid
               console.log(this.privacy)
               console.log(data)
               var storeName = (this.isAdmin) ? 'adminUsers/updatePrivacy' : 'managerUsers/updatePrivacy'
               this.$store.dispatch(storeName,data).then((response) => {
                    console.log('updatePrivacy success!',response)
                    this.loading = false;
                    location.reload();
               })
               .catch((err) => {
                    console.error('LOGIN ERROR!',err)
                    this.message = err.response.data.message
                    this.errors = err.response.data.errors
                    this.loading = false;
               }) 
          }
     }
}
</script>
