var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"2"}},[_c('v-avatar',[_c('v-img',{attrs:{"src":'/img/flags/'+_vm.user.country.toLowerCase()+'.png'}})],1)],1),_c('v-col',{staticClass:"text-md-left text-center"},[_c('h3',[_vm._v(_vm._s(_vm.user.email))]),_vm._v(" "+_vm._s(_vm.user.profile.fullname)+" ")]),_c('v-col',{staticClass:"text-md-right text-center",attrs:{"cols":"12","md":"3"}},[_vm._v(" "+_vm._s(_vm.user.uid)+" ")])],1)],1),_c('v-divider'),(_vm.alert.visible)?_c('v-row',{staticClass:"pa-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"border":"left","type":_vm.alert.type,"icon":_vm.alert.icon,"prominent":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.alert.title)))]),_vm._v(" "+_vm._s(_vm.$t(_vm.alert.text))+" ")]),_c('v-col',[_c('v-btn',{attrs:{"block":"","color":"dark","target":"_blank","to":_vm.alert.btn_to}},[_vm._v(_vm._s(_vm.$t(_vm.alert.btn_text))+" ")])],1)],1)],1)],1)],1):_vm._e(),_c('v-divider'),_c('v-row',{staticClass:"pa-5",attrs:{"center":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[_c('v-progress-circular',{attrs:{"rotate":-90,"color":_vm.user.points.level,"value":_vm.user.points.percent,"size":150,"width":15,"block":""}},[_c('span',{staticClass:"h1 display-3"},[_vm._v(_vm._s(_vm.user.points.total_points))])]),_c('br'),_c('h3',{class:'h1 display-1 my-3 '+ _vm.user.points.level+'--text'},[_vm._v(" "+_vm._s(_vm.$t('commons.levels.'+_vm.user.points.level+'.title'))+" ")])],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('commons.profile')))]),(!_vm.alert.visible)?_c('simple-table',{attrs:{"table":{
                    'uid':_vm.user.uid,
                    'email':_vm.user.email,
                    'phone':_vm.user.phone,
                    'country':_vm.user.country+'-'+_vm.user.language,
                    'city':_vm.user.profile.city+', '+_vm.user.profile.zip_code,
                    'fullname':_vm.user.profile.fullname,
                    'date_of_birth':_vm.user.profile.date_of_birth,
                    'age':_vm.user.profile.age,
               }}}):_c('simple-table',{attrs:{"table":{
                    'uid':_vm.user.uid,
                    'email':_vm.$t('user.hidden-data'),
                    'phone':_vm.$t('user.hidden-data'),
                    'country': _vm.$t('user.hidden-data'),
                    'city':_vm.$t('user.hidden-data'),
                    'fullname':_vm.$t('user.hidden-data'),
                    'date_of_birth':_vm.$t('user.hidden-data'),
                    'age':_vm.$t('user.hidden-data'),
               }}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-row',{staticClass:"text-center"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('attributes.email_is_verified.label')))]),(_vm.user.email_verified_at)?_c('div',[_c('v-icon',{attrs:{"color":"success accent-4"}},[_vm._v("mdi-check")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")([ _vm.user.email_verified_at ],"dddd, D MMMM YYYY"))+" ")],1):_c('div',[_c('v-icon',{attrs:{"color":"red accent-4 "}},[_vm._v("mdi-cancel")]),_c('br'),_c('dialog-simple',{attrs:{"btnclass":"my-1","button":_vm.$t('commons.resend-email-verification.btn'),"title":_vm.$t('attributes.email_is_verified.label')}},[_c('FormResendEmailVerification',{attrs:{"email":_vm.user.email}})],1)],1)]),_c('v-col',[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v(_vm._s(_vm.$t('attributes.phone_is_verified.label')))]),(_vm.user.phone_verified_at)?_c('div',[_c('v-icon',{attrs:{"color":"success accent-4"}},[_vm._v("mdi-check")]),_c('br'),_vm._v(" "+_vm._s(_vm._f("moment")([ _vm.user.phone_verified_at ],"dddd, D MMMM YYYY"))+" ")],1):_c('div',[_c('v-icon',{attrs:{"color":"red accent-4"}},[_vm._v("mdi-cancel")]),_c('br'),_c('dialog-simple',{attrs:{"btnclass":"my-1","button":_vm.$t('commons.resend-sms-verification.btn'),"title":_vm.$t('attributes.phone_is_verified.label')}},[_c('FormResendSmsVerification',{attrs:{"phone":_vm.user.phone},on:{"refresh":_vm.refreshUser}})],1),_c('br'),_c('dialog-simple',{attrs:{"btnclass":"my-1","button":_vm.$t('commons.resend-sms-verification.verify'),"title":_vm.$t('attributes.phone_is_verified.label')}},[_c('FormResendSmsVerification',{attrs:{"sent":"","phone":_vm.user.phone},on:{"refresh":_vm.refreshUser}})],1)],1)])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('h4',[_vm._v(_vm._s(_vm.$t('commons.privacy')))]),(!_vm.alert.visible)?_c('simple-table',{attrs:{"table":{
                    'general':_vm.user.privacy.general,
                    'marketing':_vm.user.privacy.marketing,
                    //'survey':user.privacy.survey,
                    'third_party':_vm.user.privacy.third_party,
                    'user_confirm': (_vm.user.privacy.user_confirm) ? true : false,
                    'updated_at':_vm.user.privacy.updated_at,
                    'created_at':_vm.user.created_at,
                    'expired_at':_vm.user.privacy.expired_at,
               }}}):_c('simple-table',{attrs:{"table":{
                    'general':_vm.$t('user.hidden-data'),
                    'marketing':_vm.$t('user.hidden-data'),
                    //'survey':$t('user.hidden-data'),
                    'third_party':_vm.$t('user.hidden-data'),
                    'user_confirm': false,
                    'updated_at': _vm.user.privacy.updated_at,
                    'created_at': _vm.user.created_at,
                    'expired_at': _vm.user.privacy.expired_at
               }}})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"4"}},[_c('h4',[_vm._v(_vm._s(_vm.user.qrcode.title))]),_c('br'),_c('v-img',{staticClass:"mx-auto",attrs:{"width":"50%","src":_vm.user.qrcode.url.image}}),_c('br'),_c('dialog-simple',{attrs:{"outlined":"","button":_vm.$t('commons.resend-card.btn') +' '+_vm.$t('commons.resend-card.title'),"title":_vm.$t('commons.resend-card.title')}},[_c('FormResendCard',{attrs:{"disabled":"","uid":_vm.user.uid}})],1),_c('br'),_c('dialog-simple',{attrs:{"outlined":"","btncolor":"dark","button":_vm.$t('commons.request-delete.title'),"title":_vm.$t('commons.request-delete.title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('commons.request-delete.text'))+" ")]),_c('FormRequestDelete',{attrs:{"disabled":"","email":_vm.user.email}})],1)],1),(_vm.isAdmin)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.roles.includes('manager') || _vm.user.roles.includes('admin')),expression:"user.roles.includes('manager') || user.roles.includes('admin')"}],attrs:{"cols":"12"}},[_c('v-divider')],1):_vm._e(),(_vm.isAdmin)?_c('v-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.roles.includes('manager') || _vm.user.roles.includes('admin')),expression:"user.roles.includes('manager') || user.roles.includes('admin')"}],attrs:{"cols":"12"}},[_c('dialog-simple',{attrs:{"outlined":"","button":_vm.$t('commons.resend-password.title'),"title":_vm.$t('commons.resend-password.title')}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('commons.resend-password.text'))+" ")]),_c('FromResendNewPassword',{attrs:{"disabled":"","uid":_vm.user.uid}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }